<template>
    <div class="page">
        <section class="section-information">
            <div class="container-information-content-1">
                <div class="container-breadcrumb">
                    <div class="bullet-point passed">1</div>
                    <div class="bullet-point passed">2</div>
                    <div class="bullet-point passed">3</div>
                    <div class="bullet-point active">4</div>
                    <div class="lign-breadcrumb"></div>
                </div>
                <div class="container-info-title">
                    <div class="icone-btn-information-small"></div>
                    <p class="information-title">Vos informations</p>
                </div>
                <p class="information-txt">
                    Si vous devez réaliser une autre livraison , sélectionnez « Autre livraison » ci-dessous.
                    <br>
                    <br>
                    Ou, retournez à l’accueil si vous avez fini.
                </p>
            </div>
            <div>
                <BtnSecondary typeOfIconLeft="deposit" msg="Déposer un autre colis" typeOfIconRight="arrow-right-white" :status="disableNewDeposit" :loader="false" v-on:click="newDeposit" v-if="this.$store.state.transaction.actor.role == 'driver' || this.$store.state.transaction.actor.role == 'fidensio'" />
                <router-link to="/"><BtnPrimary typeOfIconLeft="arrow-left" msg="J’ai fini" typeOfIconRight="logout"/></router-link>
            </div>

        </section>

        <section class="section-action">
            <div class="container-txt-depots">
                <div class="content-txt-validation">
                    <div class="circle-validation">
                        <div class="icone-validation"></div>
                    </div>
                    <p class="type-one">
                        Livraison réussie<br>
                        Merci !
                    </p>
                </div>
            </div>
        </section>

        <div class="modal confirm" v-if="showFullLockerModal">
            <h1>Désolé</h1>
            <p>Tous les casiers sont occupés<br>
                vous ne pouvez pas déposer un autre colis pour le moment</p>
            <br>
            <BtnPrimary msg="J'ai fini" v-on:click="goHome" :loader="false" />
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import BtnPrimary from "@/components/Atoms/BtnPrimary";
import BtnSecondary from "@/components/Atoms/BtnSecondary";

export default {
  name: 'DeliveryDepots',
  components: { BtnPrimary, BtnSecondary},
  data: function () {
      return {
        disableNewDeposit: false,
        showFullLockerModal: false
      }
    },
    methods: {
        goHome: function() {
            this.$router.push('/')
        },
        resetStore: function() {
            let actor = this.$store.state.transaction.actor
            let action = this.$store.state.transaction.action
            this.$store.commit('restart')
            this.$store.commit('setActor', actor)
            this.$store.commit('setAction', action)
        },
        newDeposit: function() {
            if (this.disableNewDeposit) {
                console.log("new deposit button is disabled")
                return
            }
            let self = this
            let options = {
                emulateJSON: true,
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${this.$store.state.auth.lockerToken}`
                }
            }
            this.$http.get(process.env.VUE_APP_API_BASE_URL + '/api/v1.0/locker/' + this.$store.state.auth.lockerId, options).then(response => {
                console.info('New deposit : checking locker status');
                // console.log(response.data);
                self.$store.commit('setLocker', response.data);
                // self.$emit('stopLoading')
                if (response.data.isFull) {
                    this.showFullLockerModal = true
                    this.disableNewDeposit = true
                    return
                } else {
                    self.resetStore();
                    self.$router.push('/deliveryrecipients')
                }
            }).catch(error => {
                // self.$emit('stopLoading')
                console.log(error)
            })
        }
    }
}
</script>


<style lang="scss" scoped>

.container-txt-depots {
    width: 83%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .content-txt-validation {
        height: 512px;
        width: 750px;
        border: 3px solid #20AC5B;
        border-radius: 30px;
        margin: auto;
        padding: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    .circle-validation {
        height: 105px;
        width: 105px;
        border-radius: 55px;
        background-color: #20AC5B;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

</style>