<template>
    <div class="container-screensaver" v-on:click="wake">
        <p class="txt-secondary m-b">Appuyez sur l’écran pour déverrouiller le locker.</p>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Sleep',
    data: function () {
        return {

        }
    },
    methods: {
        wake: function() {
            this.$emit('wake')
            // this.$router.push('/')
        }
    },
    mounted: function() {
        /* window.Echo.channel('ClaraLockers-development').listen('locker-update', (payload) => {
            console.log('echo : data received')
            console.log(payload);
        }) */
    }
}
</script>

<style lang="scss" scoped>

.container-screensaver {
    background-image: url("~@/assets/img/splash_screen.png");
    background-position: center;
    background-repeat: no-repeat;
    /* background-size: cover; */
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.m-b {
    margin-bottom: 35px;
}

</style>