<template>
    <div id="start" class="page">
        <section id="main-information">
            <div>
                <div>
                    <img src="@/assets/img/clara_lockers.png" class="logo" alt="Logo Clara Lockers">
                </div>
                <div>
                    <p class="word-action-large">Bienvenue</p>
                    <p class="word-action">Que souhaitez-vous</p>
                    <p class="word-action">faire aujourd’hui ?</p>
                </div>
            </div>
            <div>
                <div class="cards-information">
                    <div class="information-login">
                        <p class="txt-login-question">Pas encore de compte Clara Lockers ?</p>
                        <p class="txt-login-answer">Inscrivez-vous en deux minutes depuis votre téléphone<br>en scannant simplement ce QR code.</p>
                    </div>
                    <div class="qr-code-registration">
                        <img src="@/assets/img/signupqrcode.png" class="qrcode" alt="Signup QR Code">
                    </div>
                </div>
                <div class="information-contact">
                    <p><span class="bold">Une question ? </span>Contactez-nous : <span class="color">support@claralockers.com</span></p>
                </div>
            </div>
            <div v-if="isAdminSession" class="version">
                {{ versionNumber }}
            </div>
        </section>
        <section id="main-menu">
            <BtnSecondary typeOfIconLeft="deposit" msg="Livrer" typeOfIconRight="arrow-right-white"
                            v-on:click="selectAction('deposit')"
                            :class="this.$store.state.locker.isFull ? 'not-available' : ''"/>

            <!--<BtnSecondary typeOfIconLeft="conciergerie" msg="Commander" typeOfIconRight="arrow-right-white"
                            v-on:click="selectAction('conciergerie')"
                            :class="this.$store.state.locker.isFull ? 'not-available' : ''" />-->

            <BtnSecondary typeOfIconLeft="withdrawal" msg="Retirer" typeOfIconRight="arrow-right-white"
                            v-on:click="selectAction('withdrawal')" />

            <!--<BtnSecondary typeOfIconLeft="withdrawal" msg="S'inscrire" typeOfIconRight="arrow-right-white"
                            v-on:click="selectAction('signup')" />-->

            <BtnSecondary typeOfIconLeft="information" msg="Information" typeOfIconRight="arrow-right-white"
                          v-on:click="selectAction('information')"/>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import BtnSecondary from "@/components/Atoms/BtnSecondary";

export default {
    name: 'Start',
    components: { BtnSecondary },
    props: ['isAdminSession'],
    data() {
        return {
            versionNumber: process.env.VUE_APP_VERSION
        }
    },
    methods: {
        selectAction: function(action) {
            if (action == "debug") {
                this.$router.push('/lockerstatus')
                return;
            }
            if (this.$store.state.locker.isFull && (action == 'deposit' || action == 'conciergerie')){
                return;
            }
            this.$gtag.event(action, {
                'event_category' : 'bbb',
                'event_label' : 'ccc'
            })
            this.$store.commit('setAction', action)
            if (action == 'deposit') {
                this.$router.push('/deliverylogin')
            }else if (action == 'information') {
                this.$router.push('/information')
            }
            else {
                this.$router.push('/login')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.logo {
    width: 220px;
    height: auto;
    margin-bottom: 40px;
}
.information-login {
    width: calc(100% - 120px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 12px;
    .txt-login-question {
        font-size: 24px;
        font-weight: bold;
    }
    .txt-login-answer {
        font-size: 24px;
    }
}
.information-contact {
    font-size: 20px;
    margin-top: 20px;
    .bold {
        font-weight: bold;
    }
    .color {
        color: #FFC107;
    }
}
.qrcode {
    object-fit: fill;
    height: 100%;
    width: 100%;
}
</style>