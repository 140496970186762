<template>
    <div id="locker" class="page">
        <section id="main-information">
            <div>
                <p class="word-action">Que puis je faire</p>
                <p class="word-action">pour vous ?</p>
            </div>
        </section>
        <section id="main-menu">
            <div class="container-depots-items">
                <div class="content-depots">
                    <div class="container-txt-depots">
                        <div class="type-one">Veuillez fermer tous les casiers</div>
                    </div>
                    <Locker :locker="locker.modules" />
                    <BtnPrimary msg="C'est bon" @click="goHome" />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import Locker from "@/components/Organisms/Locker";
import BtnPrimary from "@/components/Atoms/BtnPrimary";

export default {
  name: 'Hold',
  components: { Locker, BtnPrimary},
  data: function () {
      return {
          locker : this.$store.state.locker
      }
    },
    methods: {
        goHome: function() {
            console.log('go home (hold page)')
            this.$emit('wake')
        }
    },
    mounted: function() {
        this.emitter.on("pusher", () => {
            console.log("pusher event received by the hold page")
            // console.log(data)
            this.$emit('wake')
        });
    },
    unmounted: function() {
        this.emitter.off("pusher")
    }
}
</script>

