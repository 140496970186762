<template>
    <div id="locker" class="page">
        <div class="container-depots-items">
            <div class="container-txt-depots">
                <div class="type-one">Merci</div>
                <ol class="content-txt-locker">
                    <li>Récupérez votre colis</li>
                    <li>Fermez la porte</li>
                </ol>
            </div>
            <Locker :locker="locker.modules" />
        </div>

        <div class="modal" v-if="showFinishedMsg">
            <h1>Merci !</h1>
            <p>Votre box est fermée, c'est parfait.<br>
            A bientôt</p>
        </div>

        <div class="footer footer-right">
            <!--<BtnPrimary msg="< Récupérer un autre colis" :status="!showFinishedMsg" v-on:click="newPickup" />-->
            <BtnPrimary msg="Fin du retrait >" :status="!showFinishedMsg" @click="leave" />
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import Locker from "@/components/Organisms/Locker";
import BtnPrimary from "@/components/Atoms/BtnPrimary";

export default {
    name: 'Pickup',
    components: { Locker, BtnPrimary},
    data: function () {
        return {
            // locker: this.$store.state.transaction.locker,
            locker: null,
            moduleIdx: this.$route.params.moduleIdx,
            boxIdx: this.$route.params.boxIdx,
            maxPollingCount: 5,
            showFinishedMsg: false,
            pollingTimer: null,
            endingTimer: null
        }
    },
    mounted: function() {
        this.pollingTimer = setTimeout(this.startPolling, 10000, 1);
        let options = {
            headers: {
                Authorization: `Bearer ${this.$store.state.auth.lockerToken}`
            }
        }
        this.$http.get(process.env.VUE_APP_API_BASE_URL + '/api/v1.0/locker/' + this.$store.state.auth.lockerId, options).then(response => {
            console.log(response.data);
            this.locker = response.data
        }).catch(error => {
            console.log(error)
        })
    },
    methods: {
        resetStore: function() {
            let actor = this.$store.state.transaction.actor
            let action = this.$store.state.transaction.action
            this.$store.commit('restart')
            this.$store.commit('setActor', actor)
            this.$store.commit('setAction', action)
        },
        leave: function() {
            if (!this.showFinishedMsg) return
            this.$emit('leave')
        },
        newPickup: function() {
            if (!this.showFinishedMsg) return
            this.resetStore();
            this.$router.push('/collectpackage')
        },
        startPolling: function(count) {
            if (count > this.maxPollingCount) return;
            count++;
            let self = this;
            console.log("Polling pickup " + count)
            this.$emit('keepAlive')
            let options = {
                emulateJSON: true,
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${this.$store.state.auth.lockerToken}`
                }
            }
            this.$http.get(process.env.VUE_APP_API_BASE_URL + '/api/v1.0/locker/' + this.$store.state.auth.lockerId + '/module/' + this.moduleIdx + '/box/' + this.boxIdx, options).then(function (response) {
                console.log(response.data)
                if (response.data.lock && !response.data.presence) {
                    self.finishPickup();
                } else {
                    self.pollingTimer = setTimeout(self.startPolling, 5000, count);
                }
            }).catch(function (err) {
                console.log(err)
                self.pollingTimer = setTimeout(self.startPolling, 5000, count);
            });
        },
        finishPickup: function() {
            this.showFinishedMsg = true;
            this.endingTimer = setTimeout(this.end, 7000)
        },
        end: function() {
            this.resetStore();
            this.$router.push('/')
        }
    },
    beforeUnmount() {
        if (this.pollingTimer) {
            clearTimeout(this.pollingTimer);
        }
        if (this.endingTimer) {
            clearTimeout(this.endingTimer);
        }
    }
}
</script>

