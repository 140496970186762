<template>
    <input type="text" class="input-txt" :placeholder="placeholder" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)">
</template>

<script>

export default {
    name: 'InputFields',
    props: {
        modelValue: String,
        placeholder: String
    },
    data: function () {
        return {}
    },
    methods: {}
}

</script>

<style lang="scss">
/*.input-txt {
    height: 70px;
    width: 100%;
    max-width: 927px;
    margin-bottom: 10px;
    margin: 0 30px 10px 30px;
    border: 2px solid #707070;
    border-radius: 40px;
    padding: 0 35px;
    font-family: "Avenir Next";
    font-size: 28px;
    color: #222222;

    &:focus-visible {
        outline: none;
    }
}*/
</style>