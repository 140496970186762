<template>
    <div id="locker" class="page">
        <section class="section-information">
            <div class="container-information-content-1">
                <div class="container-breadcrumb">
                    <div class="bullet-point passed">1</div>
                    <div class="bullet-point passed">2</div>
                    <div class="bullet-point active">3</div>
                    <div class="bullet-point coming">4</div>
                    <div class="lign-breadcrumb"></div>
                </div>
                <div class="container-info-title">
                    <div class="icone-btn-information-small"></div>
                    <p class="information-title">Vos informations</p>
                </div>
                <p class="information-txt">
                    Déposez votre colis dans la box ouverte et refermer la porte.
                    <br>
                    <br>
                    Box trop petite ? Refermez la porte et cliquez sur la taille désirée à droite de l’écran.
                </p>
            </div>
            <div>
                <!--<BtnSecondary typeOfIconLeft="deposit" msg="Déposer un autre colis" typeOfIconRight="arrow-right-white" :status="!showFinishedMsg" v-on:click="newDeposit" v-if="this.$store.state.transaction.actor.role == 'driver' || this.$store.state.transaction.actor.role == 'fidensio'" />-->
                <!--<BtnSecondary typeOfIconLeft="arrow-left-white" msg="Précédent" typeOfIconRight="" v-on:click="backOrder"/>-->

                <router-link to="/" v-if="boxLock">
                    <BtnPrimary :status="false" typeOfIconLeft="arrow-left" msg="Retour à l’accueil" typeOfIconRight="home"/>
                </router-link>
                <div v-else>
                    <BtnPrimary :status="true" typeOfIconLeft="arrow-left" msg="Retour à l’accueil" typeOfIconRight="home"/>
                </div>

            </div>

        </section>

        <section class="section-action" v-if="locker != null">
            <div class="type-one">Casier ouvert</div>
            <div class="container-btn-box-size">

                <div class="content-box-size">
                    <div class="usual-box">
                    <!-- <a v-on:click="selectBox('s')" class="box-size size-s" :class="this.locker.availableSizes['s'] > 0 ? 'available' : 'not-available'" :disabled="parseInt(this.locker.availableSizes['s']) < 1">Petit</a> -->
                    <a v-on:click="selectBox('s')" class="box-size size-s" :class="boxSize == 's' ? 'open' : '' + this.locker.availableSizes['s'] > 0 ? 'available' : 'not-available'" :disabled="parseInt(this.locker.availableSizes['s']) < 1">Petit</a>
                    <a v-on:click="selectBox('m')" class="box-size size-m" :class="boxSize == 'm' ? 'open' : '' + this.locker.availableSizes['m'] > 0 ? 'available' : 'not-available'" :disabled="parseInt(this.locker.availableSizes['m']) < 1">Moyen</a>
                    <span class="box-unreal-one"></span>
                    <span class="box-unreal-two"></span>
                    <span class="box-unreal-three"></span>
                    <!-- <a v-on:click="selectBox('p-l')" class="box-size size-p-l" :class="this.locker.availableSizes['p-l'] > 0 ? 'available' : 'not-available'" :disabled="parseInt(this.locker.availableSizes['p-l']) < 1">Large</a> -->
                    <a v-on:click="selectBox('p-l')" class="box-size size-p-l" :class="boxSize == 'p-l' ? 'open' : '' + this.locker.availableSizes['p-l'] > 0 ? 'available' : 'not-available'" :disabled="parseInt(this.locker.availableSizes['l']) < 1">Haut</a>
                    <a v-on:click="selectBox('p-xl')" class="box-size size-p-xl" :class="boxSize == 'p-xl' ? 'open' : ''  + this.locker.availableSizes['p-xl'] > 0 ? 'available' : 'not-available'" :disabled="parseInt(this.locker.availableSizes['p-xl']) < 1">Très Haut</a>
                    </div>
                </div>

            </div>
        </section>

        <div class="modal confirm" v-if="showChangeSizeMsg">
            <h1>Attention</h1>
            <p>Avant de pouvoir sélectionner une autre taille de box,<br>
                Veuillez fermer la box qui s'est ouverte</p>
            <br>
            <BtnPrimary :status="false" msg="C'est fait" v-on:click="selectBox" :loader="false" />
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
//import Locker from "@/components/Organisms/Locker";
import BtnPrimary from "@/components/Atoms/BtnPrimary";
// import BtnSecondary from "@/components/Atoms/BtnSecondary";

export default {
    name: 'Deposit',
    components: { BtnPrimary}, /*Locker*/
    data: function () {
        return {
            eventing: "pushing", // use "polling" or "pushing" > NOTE polling is no longer supported with v2
            /*locker: this.$store.state.transaction.locker,*/
            // locker : this.$store.state.locker,
            locker: null,
            moduleIdx: this.$route.params.moduleIdx,
            boxIdx: this.$route.params.boxIdx,
            boxSize: this.$route.params.boxSize,
            boxLock: false,
            boxPresence: false,
            showFinishedMsg: false,
            showChangeSizeMsg: false,
            maxPollingCount: 15,
            action : this.$store.state.transaction.action ? this.$store.state.transaction.action : 'deposit',
            pollingTimer: null,
            endingTimer: null
        }
    },
    mounted: function() {
        /* if (this.eventing == "polling") {
            this.pollingTimer = setTimeout(this.startPolling, 10000, 1);
        } */
        if (this.eventing == "pushing") {
            this.emitter.on("pusher", (data) => {
                console.log("We are waiting for an update for module " + this.moduleIdx + " box " + this.boxIdx)
                console.log("pusher event received by the deposit page")
                data = data.data
                console.log(data)
                let module = data.locker.modules.filter(elt => elt.physicalPositioning == this.moduleIdx)[0]
                console.log(module)
                let box = module.status[parseInt(this.boxIdx-1)]
                console.log(box)
                if (box.lock == true) {
                    this.showChangeSizeMsg = false
                    this.boxLock = true
                    this.boxSize = ''
                    if (box.presence == true) {
                        console.log('deposit completed')
                        return this.finishDeposit();
                    }
                }
            });
        }
    },
    unmounted: function() {
        this.emitter.off("pusher")
    },
    methods: {
        backOrder: function() {
            this.$router.push('/deliveryrecipients');
        },
        resetStore: function() {
            let actor = this.$store.state.transaction.actor
            let action = this.$store.state.transaction.action
            this.$store.commit('restart')
            this.$store.commit('setActor', actor)
            this.$store.commit('setAction', action)
        },
        leave: function() {
            if (!this.showFinishedMsg) return
            this.$emit('leave')
        },
        selectBox: function(size) {
            if (!this.boxLock) {
                this.showChangeSizeMsg = true;
                return
            } else {
                this.showChangeSizeMsg = false;
            }
            var self = this
            this.$emit('loading')
            let options = {
                emulateJSON: true,
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${this.$store.state.auth.lockerToken}`
                }
            }
            this.$http.put(process.env.VUE_APP_API_BASE_URL + '/api/v1.0/transaction/' + this.$store.state.transaction.id + '/size', {size: size}, options).then( response => {
                self.$emit('stopLoading')
                self.$store.commit('setTransactionBox', response.data.boxPosition);
                self.$store.commit('setTransactionModule', response.data.modulePosition);
                this.boxIdx = response.data.boxPosition
                this.moduleIdx = response.data.modulePosition
                this.boxSize = response.data.boxSize
                this.boxLock = false
            }).catch(error => {
                console.log(error)
                self.$emit('stopLoading')
                alert('Une erreur est survenue (33)')
            })
        },
        finishDeposit() {
            if (this.eventing == "pushing") {
                this.$router.push('/deliverydepots')
            } else {
                this.showFinishedMsg = true;
                this.endingTimer = setTimeout(this.end, 15000)
            }
        },
        end: function() {
            this.resetStore();
            this.$router.push('/')
        }
    },
    beforeMount() {
        let options = {
            headers: {
                Authorization: `Bearer ${this.$store.state.auth.lockerToken}`
            }
        }
        let self = this
        this.$http.get(process.env.VUE_APP_API_BASE_URL + '/api/v1.0/locker/' + this.$store.state.auth.lockerId, options).then(response => {
            console.log(response.data);
            self.locker = response.data
        }).catch(error => {
            console.log(error)
        })
    },
    beforeUnmount() {
        if (this.pollingTimer) {
            clearTimeout(this.pollingTimer);
        }
        if (this.endingTimer) {
            clearTimeout(this.endingTimer);
        }
    }
}
</script>
<style lang="scss">
.container-btn-box-size {
    height: 824px;
    width: 924px;
    border-radius: 30px;
    border: 3px solid #DDDDDD;
    margin: 0 auto;
    padding: 72px 0;
}
.usual-box {
    display: grid;
    justify-content: center;
    grid-template-columns: [first] 158px [line2] 56px [line3] 158px [line4] 56px [line5] 158px [line6] 56px [line7] 158px;
    grid-template-rows: [row1-start] 204px [line2] 42px [line3] 196px [line4] 42px [line5] 204px;
    margin: auto;
    .box-size {
        font-size: 30px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 36px;
        font-weight: 600;
    }
    .size-s {
        grid-column: 1;
        grid-row: 1;
    }
    .size-m {
        grid-column: 1 / 4;
        grid-row: 3;
    }
    .box-unreal-one {
        grid-column: 1;
        grid-row: 5;
        background-color: #F6F5F4;
    }
    .box-unreal-two {
        grid-column: 3;
        grid-row: 5;
        background-color: #F6F5F4;
    }
    .box-unreal-three {
        grid-column: 3 / 6;
        grid-row: 1;
        background-color: #F6F5F4;
    }
    .size-p-l {
        grid-column: 5;
        grid-row: 3 / 7;
    }
    .size-p-xl {
        grid-column: 7;
        grid-row: 1 / 7;
    }
}

</style>