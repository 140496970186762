<template>
    <div class="container-input">
        <div class="content-input">
            <InputFields v-model="input" @update:modelValue="updateSuggestions" />
            <Proposal :suggestions="this.suggestions" :allowWriteIns="allowWriteIns" :autocomplete="autocomplete" :msg="input" v-if="input.length" @itemSelected="onItemSelected" />
        </div>

        <div>
            <KeyBoardCompany v-if="showCompanyLogos"/>
            <KeyBoard v-if="!numericField" @keypress="keypress" :showSpecialChars="showSpecialChars" />
            <KeyBoardNumber v-if="numericField" :codeField="codeField" @keypress="keypress" />
        </div>
    </div>
</template>

<script>

import KeyBoardCompany from "@/components/Molecules/KeyBoardCompany";
import KeyBoard from "@/components/Molecules/KeyBoard";
import KeyBoardNumber from "@/components/Molecules/KeyBoardNumber";
import InputFields from "@/components/Atoms/InputFields";
import Proposal from "@/components/Atoms/Proposal";

export default {
    name: 'FormBasic',
    props: ['allowWriteIns', 'autocomplete', 'showCompanyLogos', 'showSpecialChars', 'codeField', 'numericField'],
    components: {Proposal, InputFields, KeyBoard, KeyBoardNumber, KeyBoardCompany},
    data: function () {
        return {
            input: '',
            suggestions: [],
        }
    },
    watch: {
        input: function(val)
        {
            this.$emit('formValueChanged', val)
        }
    },
    methods: {
        updateSuggestions() {
            let self = this;
            let options = {
                emulateJSON: true,
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${this.$store.state.auth.lockerToken}`
                }
            }
            let suggestion_limit = 5
            if (this.allowWriteIns) {
                suggestion_limit = 3
            }
            switch (this.autocomplete) {
                case 'users':
                    this.$http.get(process.env.VUE_APP_API_BASE_URL + '/api/v1.0/user?fullName=' + this.input + '&limit=' + suggestion_limit, options).then( response => {
                        self.suggestions = response.data;
                    })
                break;
            }
        },
        selectSuggestedItem(id, name) {
            let item = {
                id: id,
                name: name
            }
            this.$emit('itemSelected', item);
        },
        onItemSelected: function(item) {
            this.$emit('itemSelected', item)
        },
        keypress: function(key/*, event*/) {
            switch (key) {
                case '':
                break;
                case '⌫':
                    this.input = this.input.substring(0, this.input.length-1);
                break;
                case 'Espace':
                    this.input += ' ';
                break;
                default:
                    if (this.codeField) {
                        if (this.input.length == 3 || this.input.length == 7) {
                            this.input += '-'
                        }
                    }
                    this.input += key;
                break;
            }
            if (this.autocomplete) {
                this.updateSuggestions();
            }
        }
    }
}
</script>

<style lang="scss">

/*.container-input {
    display: flex;
    //height: 90%;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: space-between;
}

.content-input {
    width: 1005px;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-content: center;
}*/

</style>
