<template>
    <div id="delivery-login" class="page">
        <section class="section-information">
            <div class="container-information-content-1">
                <div class="container-breadcrumb">
                    <div class="bullet-point active">1</div>
                    <div class="bullet-point coming">2</div>
                    <div class="bullet-point coming">3</div>
                    <div class="bullet-point coming">4</div>
                    <div class="lign-breadcrumb"></div>
                </div>
                <div class="container-info-title">
                    <div class="icone-btn-information-small"></div>
                    <p class="information-title">Vos informations</p>
                </div>
                <p class="information-txt">
                    Indiquez votre Prénom & Nom.
                    <br>
                    <br>
                    Vous êtes livreur ?
                    <br>
                    Sélectionnez le logo de votre entreprise parmi la liste. <br>
                    Sinon, vous pouvez indiquer le nom de votre société ou votre propre nom dans la barre de recherche.
                    <br>
                    <br>
                    <i>Pour toute question, contactez-nous : support@claralockers.com</i>
                </p>
            </div>
            <div>
                <router-link to="/"><BtnPrimary typeOfIconLeft="arrow-left" msg="Retour à l’accueil" typeOfIconRight="home"/></router-link>
            </div>

        </section>

        <section class="section-action">
            <div class="type-one">Identifiez vous</div>
                <FormBasic showCompanyLogos="true" @itemSelected="itemSelected" @formValueChanged="readFormValue" v-bind:allowWriteIns="allowWriteIns" autocomplete="users"/>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import BtnPrimary from "@/components/Atoms/BtnPrimary";
import FormBasic from "@/components/Organisms/FormBasic";

export default {
    name: 'DeliveryLogin',
    components: {FormBasic, BtnPrimary},
    data: function () {
        return {
            showInputText: false,
            companyName: '',
            companies: [
                'fidensio',
                'colissimo',
                'dhl',
                'amazon',
                'ups',
                'chronopost',
                'tnt',
                'dpd',
                'fedex',
                'gls'
            ],
            allowWriteIns: true
        }
    },
    methods: {
        readFormValue: function(value) {
          this.companyName = value
        },
        itemSelected: function(value) {
            this.$store.commit('setActor', {
                id: value.id,
                name: value.name,
                role: 'guest-deposit'
            })
            this.$router.push('/deliveryrecipients')
        },
        selectDeliveryCompany(companyName) {
            this.$store.commit('setActor', {
                id: -1,
                name: companyName,
                role: 'driver'
            })
            this.$router.push('/deliveryrecipients')
        }
    }
}
</script>
