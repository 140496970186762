<template>
    <div :class="'key key-' + msg ">
        {{ msg }}
    </div>
</template>

<script>
export default {
    name: 'Key',
    props: {
        msg: String
    }
}
</script>

<style lang="scss">

</style>