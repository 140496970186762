import { createStore } from 'vuex'

export default createStore({
    state: {
        auth: {
            lockerId: null,
            lockerToken: null
        },
        locker: {
            isReady: null,
            isFull: null,
            modules: [],
            availableSizes: []
        },
        transaction: {
            action: null,
            actor: {
                id: 0,
                name: '',
                fidensioUserId: 0,
                fidensioUserEmail: '',
                fidensioUserHasBankCard: false
            },
            recipient: {
                id: 0,
                name: ''
            },
            locker: '',
            box: '',
            module: '',
            startTime: null,
            endTime: null,
            service: null,
            serviceDryCleaning: {
                chosenServices: [],
                comments: ''
            },
            serviceCarWash: {
                chosenTypeVehicle :'',
                cleaningOption: '',
                numberplate: '',
                address: '',
                chosenDate: '',
                chosenTime: ''
            },
            // locker: {}
        }
    },
    mutations: {
        restart (state) {
            state.transaction = {
                id: null,
                action: null,
                actor: {
                    id: 0,
                    name: '',
                    role: 'user',
                    fidensioUserId: 0,
                    fidensioUserEmail: '',
                    fidensioUserHasBankCard: false
                },
                recipient: {
                    id: 0,
                    name: ''
                },
                locker: '',
                box: '',
                module: '',
                startTime: null,
                endTime: null,
                service: null,
                serviceDryCleaning: {
                    chosenServices: [],
                    comments: ''
                },
                serviceCarWash: {
                    chosenTypeVehicle :'',
                    cleaningOption: '',
                    numberplate: '',
                    address: '',
                    chosenDate: '',
                    chosenTime: ''
                }
            }
        },
        setLockerId(state, id) {
            state.auth.lockerId = id
        },
        setLockerToken(state, token) {
            state.auth.lockerToken = token
        },
        setLocker(state, data) {
            state.locker.isFull = data.isFull
            state.locker.isReady = data.isReady
            state.locker.availableSizes = {
                ...data.availableSizes
            }
            state.locker.modules = {
                ...data.modules
            }
        },
        setAction (state, action) {
            state.transaction.action = action
        },
        setActor (state, data) {
            state.transaction.actor.id = data.id;
            state.transaction.actor.name = data.name;
            state.transaction.actor.role = data.role;
            state.transaction.actor.fidensioUserId = data.fidensioUserId;
            state.transaction.actor.fidensioUserEmail = data.fidensioUserEmail;
            state.transaction.actor.fidensioUserHasBankCard = data.fidensioUserHasBankCard;
        },
        setRecipient(state, item) {
            state.transaction.recipient.id = item.id;
            state.transaction.recipient.name = item.name;
        },
        setServiceDryCleaning (state, data) {
            state.transaction.service = 'drycleaning'
            state.transaction.serviceDryCleaning = {
                chosenServices: data.selectedServices
            }
        },
        setCommentDryCleaning (state, data) {
            state.transaction.serviceDryCleaning.comments = data
        },
        setTypeVehicleCarWash (state, data) {
            state.transaction.serviceCarWash.chosenTypeVehicle = data
        },
        setCleaningOptionCarWash (state, data) {
            state.transaction.serviceCarWash.cleaningOption = data
        },
        setAddressCarWash (state, data) {
            state.transaction.serviceCarWash.address = data
        },
        setNumberplateCarWash (state, data) {
            state.transaction.serviceCarWash.numberplate = data
        },
        setDateCarWash (state, data) {
            state.transaction.serviceCarWash.chosenDate = data
        },
        setTimeCarWash (state, data) {
            state.transaction.serviceCarWash.chosenTime = data
        },
        setTransactionId(state, data) {
            state.transaction.id = data;
        },
        setTransactionLocker(state, data) {
            state.transaction.locker = {
                ...data
            }
        },
        setTransactionModule(state, data) {
            state.transaction.module = {
                ...data
            }
        },
        setTransactionBox(state, data) {
            state.transaction.box = {
                ...data
            }
        }
    },
    actions: {
    },
    modules: {
    }
})
