<template>
  <button :class="status ? className + ' btn-primary void-value' : className + ' btn-primary'" v-on:click="loading">
      <div :class="'icone-btn-' + typeOfIconLeft"></div>
      <span class="button__text">{{ msg }}</span>
      <div :class="'icone-btn-' + typeOfIconRight"></div>
  </button>
</template>

<script>
export default {
  name: 'BtnPrimary',
  props: {
      status: Boolean,
      msg: String,
      loader: Boolean,
      typeOfIconLeft : String,
      typeOfIconRight : String
  },
  data: function() {
    return {
      className: ''
    }
  },
  methods: {
    loading: function() {
      // console.log("click on primary button")
      // this.$emit('btnclick')
      if (!this.loader) return
      this.className = 'loading'
      // setTimeout(this.stoploading, 6000)
    },
    stoploading: function() {
      this.className = ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/assets/scss/_colorsFidensio.scss";
.btn-primary {
    margin: 0 auto;
    height: 85px;
    width: 527px;
    padding: 0 35px;
    border-radius: 43px;
    border: 2px solid $color-3;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 30px;
    font-weight: 500;
    color: $color-3;
}

.void-value {
    opacity: 50%;
}

.loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
</style>
