import Pusher from 'pusher-js'

import { createApp } from 'vue'
import App from './App.vue'
import mitt from 'mitt';
import './registerServiceWorker'
import axios from 'axios'
import VueAxios from 'vue-axios'

import router from './router'
import store from './store'
import VueGtag from "vue-gtag-next";
import { trackRouter } from "vue-gtag-next";

console.log(process.env.VUE_APP_MIX_PUSHER_APP_KEY)
console.log(process.env.VUE_APP_PUSHER_CHANNEL)

let pusher = new Pusher(process.env.VUE_APP_MIX_PUSHER_APP_KEY, { cluster: 'eu' })
pusher.logToConsole = true
pusher.subscribe(process.env.VUE_APP_PUSHER_CHANNEL)
pusher.bind('locker.update', data => {
    console.log("Data recevied from Pusher : ")
    console.log(data)
    emitter.emit('pusher', data)
})

// Realtime events
const emitter = mitt();

// Font awesome
// https://stackoverflow.com/questions/66389974/using-font-awesome-in-vue-3
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone } from "@fortawesome/free-solid-svg-icons";



library.add(faPhone);

axios.defaults.withCredentials = true

/* axios.interceptors.request.use(function (config) {
    const kioskId = localStorage.getItem('kioskId');
    config.headers.kioskId =  kioskId;
    return config;
}); */



// console.log(EchoInstance)

const app = createApp(App)

app.use(store)
    .use(router)
    .use(VueGtag, {
        property: { id: "G-0N38CXH9MB" }
    })
    .use(VueAxios, axios)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount('#app')

app.config.globalProperties.emitter = emitter;


// tracking https://matteo-gabriele.gitbook.io/vue-gtag/v/next/basic-migration-guide
// tracking https://matteo-gabriele.gitbook.io/vue-gtag/v/next/router-tracking
trackRouter(router, {
    usePageview: true,
    useScreenview: true
});