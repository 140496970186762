<template>
    <div id="recipients" class="page">
        <section class="section-information">
            <div class="container-information-content-1">
                <div class="container-breadcrumb">
                    <div class="bullet-point passed">1</div>
                    <div class="bullet-point active">2</div>
                    <div class="bullet-point coming">3</div>
                    <div class="bullet-point coming">4</div>
                    <div class="lign-breadcrumb"></div>
                </div>
                <div class="container-info-title">
                    <div class="icone-btn-information-small"></div>
                    <p class="information-title">Vos informations</p>
                </div>
                <p class="information-txt">
                    Votre destinataire doit être obligatoirement inscrit au service pour recevoir un colis
                    <br>
                    <br>
                    Indiquez son Prénom & Nom et sélectionnez celui-ci dans les suggestions
                </p>
            </div>
            <div>
                <router-link to="/deliverylogin"><BtnSecondary typeOfIconLeft="arrow-left-white" msg="Précédent" /></router-link>
                <router-link to="/"><BtnPrimary typeOfIconLeft="arrow-left" msg="Retour à l’accueil" typeOfIconRight="home"/></router-link>
            </div>

        </section>

        <section class="section-action">
            <div class="type-one">Vous livrez qui ?</div>
            <FormBasic v-bind:allowWriteIns="allowWriteIns" autocomplete="users" @itemSelected="selectRecipient" />
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import BtnPrimary from "@/components/Atoms/BtnPrimary";
import BtnSecondary from "@/components/Atoms/BtnSecondary";
import FormBasic from "@/components/Organisms/FormBasic";

export default {
    name: 'Deliveryrecipients',
    components: {FormBasic, BtnPrimary, BtnSecondary},
    data: function () {
        return {
            allowWriteIns: false
        }
    },
    methods: {
        selectRecipient(recipient) {
            this.$store.commit('setRecipient', recipient)
            this.$emit('loading')
            let self = this
            console.log("Start transaction API call")
            let options = {
                emulateJSON: true,
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${this.$store.state.auth.lockerToken}`
                }
            }
            let data = {
                lockerId: this.$store.state.auth.lockerId,
                receiverId: recipient.id,
            }
            if (this.$store.state.transaction.actor.id == -1) {
                data.senderName = this.$store.state.transaction.actor.name
            } else {
                data.senderId = this.$store.state.transaction.actor.id
            }
            this.$http.post(process.env.VUE_APP_API_BASE_URL + '/api/v1.0/transaction', data, options).then( response => {
                console.log(response.data)
                self.completeTransaction(response.data)
            }).catch(error => {
                console.log(error)
                this.$emit('stopLoading')
                alert('Une erreur est survenue (11)')
            })
        },
        completeTransaction: function(data) {
            this.$emit('stopLoading')
            this.$store.commit('setTransactionId', data.id);
            this.$store.commit('setTransactionBox', data.boxPosition);
            this.$store.commit('setTransactionModule', data.modulePosition);
            this.$router.push({name: 'Deposit', params: {
                boxIdx: data.boxPosition,
                moduleIdx: data.modulePosition,
                boxSize: data.boxSize
            }})
        }
    }
}
</script>
