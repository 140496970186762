<template>
    <div class="company-board">
        <div v-for="company in companies" :key="company">
            <div :class="'icon-delivery-company dc-' + company" v-on:click="selectDeliveryCompany(company)"></div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'KeyBoardCompany',
    data: function () {
        return {
            companyName: '',
            companies: [
                'fidensio',
                'colissimo',
                'dhl',
                'amazon',
                'ups',
                'chronopost',
                'tnt',
                'dpd',
                'fedex',
                'gls'
            ],
        }
    },
    methods: {
        toggleDeliveryType: function() {
            this.showInputText =! this.showInputText
        },
        readFormValue: function(value) {
            this.companyName = value
        },
        selectCustomDeliveryCompany: function() {
            this.selectDeliveryCompany(this.companyName)
        },
        selectDeliveryCompany(companyName) {
            this.$store.commit('setActor', {
                id: -1,
                name: companyName,
                role: 'driver'
            })
            this.$router.push('/deliveryrecipients')
        }
    }
}
</script>

<style lang="scss">

.company-board{
    display: grid;
    grid: auto-flow / repeat(10, 107px);
    justify-items: center;
    //grid: auto-flow / repeat(5, 250px);
}

.container-items-company {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.container-items-company-two {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    height: 100%;
}

.icon-delivery-company {
    width: 75px;
    height: 75px;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 16px;
    //padding: 10px;
}

.dc-fidensio {
    background-image: url("~@/assets/img/delivery/delivery-2.jpg");
}
.dc-colissimo {
    background-image: url("~@/assets/img/delivery/delivery-7.jpg");
}
.dc-dhl {
    background-image: url("~@/assets/img/delivery/delivery-8.jpg");
}
.dc-amazon {
    background-image: url("~@/assets/img/delivery/delivery-9.jpg");
}
.dc-ups {
    background-image: url("~@/assets/img/delivery/delivery-5.jpg");
}
.dc-chronopost {
    background-image: url("~@/assets/img/delivery/delivery-1.jpg");
}
.dc-tnt {
    background-image: url("~@/assets/img/delivery/delivery-4.jpg");
}
.dc-dpd {
    background-image: url("~@/assets/img/delivery/delivery-6.jpg");
}
.dc-fedex {
    background-image: url("~@/assets/img/delivery/delivery-3.jpg");
}
.dc-gls {
    background-image: url("~@/assets/img/delivery/delivery-10.jpg");
}

</style>