<template>
    <div class="loader-overlay" v-if="showLoader" v-on:click="restart()">
        <div class="loader">
            <img src="@/assets/img/loader.gif" alt="Chargement...">
        </div>
    </div>
    <router-view :class="appClass" v-slot="{ Component }" v-on:click="stayAwake()" @keepAlive="stayAwake()" @loading="startLoader" @stopLoading="stopLoader" @leave="leave" @wake="restart" @login="newSession">
        <transition :name="transitionName" mode="out-in">
            <component :is="Component" :key="$route.path" :isAdminSession="isAdminSession" />
        </transition>
    </router-view>

</template>

<script>

export default {
    name: 'App',
    components: {},
    data: function () {
        return {
            versionNumber: process.env.VUE_APP_VERSION,
            isAdminSession: false,
            showLoader: false,
            sleepInterval: 5000,
            refreshInterval: 1800000,
            sleepAfter: 60,
            lastActivity: 0,
            locker: {},
            user: [],
            transitionName: 'fade'
        }
    },
    watch: {
        '$route' (to, from) {
            if (from.path == '/deliveryrecipients' || from.path == '/collectpackages' || from.path == '/sleep' || from.path == '/hold') {
                this.transitionName = '';
                return;
            }
            if (to.path == '/deposit' || to.path == '/pickup' || to.path == '/hold' || to.path == '/sleep') {
                this.transitionName = '';
                return;
            }
            if (to.path == '/' && this.from != '/') {
                this.restart()
            }
            this.transitionName = 'fade'
        }
    },
    computed: {
        // theme selection : claralockers or fidensio
        appClass: function() {
            if (process.env.VUE_APP_THEME === "claralockers") return 'clara'
            return 'fidensio'
        },
        showLeaveButton: function() {
            if (this.$route.name == 'Hold') return false;
            return true;
        },
        showSkipButton: function() {
            switch (this.$route.name) {
                case 'CommentsOLD':
                    return true;
                default:
                    return false
            }
        },
        textJourney : function () {
            switch (this.$store.state.transaction.action) {
                case 'delivery':
                    return 'Espace livreurs';
                case 'deposit':
                    return 'Dépôt';
                case 'conciergerie':
                    return 'Conciergerie';
                case 'withdrawal':
                    return 'Retrait';
                default:
                    return ''
            }
        },
        imgJourney : function () {
            switch (this.$store.state.transaction.action) {
                case 'delivery':
                    return 'deposit';
                case 'deposit':
                    return 'deposit';
                case 'conciergerie':
                    return 'concierge-key';
                case 'withdrawal':
                    return 'user';
                default:
                    return ''
            }
        }
    },
    methods: {
        leave: function() {
            this.$store.commit('restart')
            this.$router.push({ name: 'Start' })
        },
        skip: function() {
            switch (this.$route.name) {
                case 'Login':
                    this.$router.push({ name: 'services' })
                    break;
                default:
                    this.$store.commit('restart')
                    this.$router.push({ name: 'Start' })
            }
        },
        currentTimestamp() {
            return Math.round(new Date().getTime() / 1000)
        },
        refreshApp() {
            console.log('Refresh ?');
            if (this.$route.name != 'Sleep') {
                console.log("refresh : no refresh : app is being used")
                setTimeout(this.refreshApp, this.refreshInterval)
            } else {
                console.log("refresh : yes")
                window.location = '/';
            }
        },
        startLoader: function() {
            this.showLoader = true;
        },
        stopLoader: function() {
            this.showLoader = false;
        },
        sleep() {
            console.log("sleep ?")
            let curTime = this.currentTimestamp()
            console.log("curTime " + curTime + " lastActivity " + this.lastActivity + " :  " + (curTime - this.lastActivity) + 's')
            if (this.$route.name != 'Sleep' && curTime > this.lastActivity + this.sleepAfter) {
                console.log("sleep : yes")
                this.showLoader = false;
                this.$store.commit('restart')
                this.$router.push('Sleep')
            } else {
                console.log("sleep : no")
            }
            setTimeout(this.sleep, this.sleepInterval)
        },
        stayAwake() {
            console.log('stay awake');
            this.lastActivity = this.currentTimestamp()
            console.log(this.lastActivity)
        },
        restart: function() {
            console.log('App Vue : restart')
            let self = this;
            this.startLoader();
            this.isAdminSession = false;
            this.$store.commit('restart')
            // Get the locker status (isReady ?)
            console.log("start")
            this.$http.get(process.env.VUE_APP_API_BASE_URL + '/api/v1.0/locker/me').then(response => {
                self.$store.commit('setLockerToken', response.data.frontToken);
                self.$store.commit('setLockerId', response.data.id);
                let options = {
                    headers: {
                        Authorization: `Bearer ${response.data.frontToken}`
                    }
                }
                this.$http.get(process.env.VUE_APP_API_BASE_URL + '/api/v1.0/locker/' + response.data.id, options).then(response => {
                    console.info('APP RESTART');
                    console.log(response.data);
                    self.$store.commit('setLocker', response.data);
                    self.stopLoader();

                    let locker = response.data
                    let isReady = locker.modules.filter(elt => elt.status.filter(elt => !elt.lock).length > 0).length == 0

                    // if (!response.data.isReady) {
                    if (!isReady) {
                        self.$router.push('/hold')
                    } else {
                        self.$router.push('/')
                    }
                }).catch(error => {
                    console.log(error)
                })
            }).catch(error => {
                console.log(error)
            })
            console.log("start end")
        },
        newSession: function(currentUser) {
            console.log('NEW SESSION')
            this.isAdminSession = false;
            if (currentUser.role == 'admin') {
                console.log("current user is an admin")
                this.isAdminSession = true;
            }
        }
    },
    mounted: async function() {
        this.showLoader = false;
        console.log("App load : we are setting the timers")
        this.lastActivity = this.currentTimestamp()
        setTimeout(this.sleep, this.sleepInterval)
        setTimeout(this.refreshApp, this.refreshInterval)
        this.restart();
    }
}
</script>

<style lang="scss">

@import "assets/scss/base";

.fidensio {
    @import "assets/scss/colorsFidensio";
    @import "assets/scss/icon";
    @import "assets/scss/accueil";
    @import "assets/scss/majorityLayout";
    @import "assets/scss/form";

}

.clara {
    @import "assets/scss/colorsClara";
    @import "assets/scss/icon";
    @import "assets/scss/accueil";
    @import "assets/scss/majorityLayout";
    @import "assets/scss/form";
}

/* Global Page Layout */@at-root

.page {
    display: flex;
    //flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.footer {
    height: 12%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 66px 40px 66px;
}

.footer-left {
    justify-content:flex-start;
}

.footer-right {
    justify-content: flex-end;
}

.footer-center {
    justify-content: center;
}

/* Header */

.header {
    height: 100px;
    display: flex;
    margin: 0 66px;
    align-items: center;

    .end-user-journey{
        /* font-family: "Avenir Next"; */
        font-weight: 400;
        font-size: 25px;
        color: #6E6E6E;
    }
    .container-general-link {
        width:20%;
    }
    .align-txt-left {
        text-align: flex-start;
    }
    .align-txt-right {
        text-align: flex-end;
    }
    .container-journey {
        display: flex;
        justify-content: center;
        width: 60%;

        .user-journey-title{
            display: flex;
            align-items: center;
            /* font-family: "Avenir Next"; */
            font-weight: 400;
            font-size: 25px;
            color: #222222;
            .icon-user-journey{
                height: 46px;
                width: 48px;
                padding-bottom: 4px;
                border-radius: 24px;
                margin-right: 30px;
                background-color: #363636;
                background-repeat: no-repeat;
                background-size: 24px;
                background-position: center;
            }
        }
    }

}

/* Loader */

.loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: white;
    opacity: 0.8;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 620px;
    height: 350px;
    margin-top: -175px;
    margin-left: -310px;
    z-index: 101;
}

/* Modal */

.modal {
    position: absolute;
    top: 50%;
    height: 200px;
    width: 800px;
    top: 50%;
    left: 50%;
    margin-left: -400px;
    margin-top: -100px;
    background-color: white;
    border: solid 1px #456789;
    border-radius: 25px;
    font-size: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.confirm {
    height: 300px;
    margin-top: -150px;
}

/* Animations */

.fade-enter-active,
.fade-leave-active {
    opacity: 100%;
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
    transform: opacity 0.3s;
}
/* Repeating code */

#main-page {
    height: calc(100vh - 120px);
}

.type-one {
    font-family: "Avenir Next";
    font-size: 40px;
    font-weight: 500;
    line-height: 40px;
    color: #222222;
    margin: 0  auto 34px auto;
}

.txt-secondary {
    font-weight: 400;
    font-size: 25px;
    color: #6E6E6E;
    margin-top: 30px;
}

.delivery-view-2 {
    display: flex;
    align-items: flex-end;
    width: 100%;
    flex-direction: row;
    height: 90%;
}

.btn-space{
    width: 13%;
    align-content: center;
    align-items: center;
}

.keyboard-space{
    width: 83%;
    height: 98%;
}

.container-depots-items {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 88%;
    .content-txt-locker {
        display: flex;
        flex-direction: column;
        align-items: center;
        li {
            font-family: Comfortaa;
            line-height: 37px;
            font-weight: 500;
            font-size: 27px;
            color: #6E6E6E;
            text-align: start;
        }
    }
}

</style>
