<template>
    <div id="keyboard" class="keyboard-number">
        <template v-for="keyrow in num">
            <Key v-for="key  in keyrow" :key="key" :content="key" :msg="key" v-on:click="keypress(key)"/>
        </template>
    </div>
</template>

<script>
import Key from "@/components/Atoms/Key"

export default {
    name: 'KeyBoardNumber',
    components: { Key },
    data: function () {
        return {
            num: [
                [ '1', '2', '3'],
                [ '4', '5', '6'],
                [ '7', '8', '9'],
                [ "-", '0', '⌫',],
            ]
        }
    },
    methods: {
        toggleSpecialChars: function() {
            this.showSpecialChars = !this.showSpecialChars;
        },
        keypress: function(key/*, event*/) {
            this.$emit('keypress', key)
        }
    }
}
</script>

<style lang="scss">

.keyboard-number {
    width: 1380px;
    display: grid;
    grid: auto-flow / repeat(3, 107px);
    justify-content: center;
}

</style>