<template>
    <div id="keyboard" class="keyboard" v-if="!showSpecialChars" >
        <template v-for="keyrow in alpha">
            <Key v-for="key  in keyrow" :key="key" :content="key" :msg="key" v-on:click="keypress(key)"/>
        </template>
    </div>
    <div id="keyboard" class="keyboard" v-else>
        <template v-for="keyrow in num">
            <Key v-for="key  in keyrow" :key="key" :msg="key" v-on:click="keypress(key)"/>
        </template>
    </div>
</template>

<script>
import Key from "@/components/Atoms/Key"

export default {
    name: 'keyBoard',
    components: { Key },
    data: function () {
        return {
            showSpecialChars: '',
            alpha: [
                ['A', 'Z', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
                ['Q', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'M'],
                ['123 ?', 'W', 'X', 'C', 'Espace', 'V', 'B', 'N', '⌫'],
            ],
            num: [
                ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0',],
                ['ABC ?', "'", '-', '+', '?', '(', ')', ',', ':', '⌫',]
            ]
        }
    },
    methods: {
        toggleSpecialChars: function() {
            this.showSpecialChars = !this.showSpecialChars;
        },
        keypress: function(key/*, event*/) {
            switch (key) {
                case '123 ?':
                    this.toggleSpecialChars();
                    break;
                case 'ABC ?':
                    this.toggleSpecialChars();
                    break;
                default:
                    this.$emit('keypress', key)
            }
        }
    }
}
</script>

<style lang="scss">
/*.keyboard {
    width: 1072px;
    display: grid;
    grid: auto-flow / repeat(10, 107px);
}*/
</style>