<template>
    <div id="collect-package" class="page">
        <section class="section-information">
            <div class="container-information-content-1">
                <div class="container-breadcrumb">
                    <div class="bullet-point passed">1</div>
                    <div class="bullet-point active">2</div>
                    <div class="lign-breadcrumb"></div>
                </div>
                <div class="container-info-title">
                    <div class="icone-btn-information-small"></div>
                    <p class="information-title">Vos informations</p>
                </div>
                <p class="information-txt">
                    Sélectionnez la ou les boxes à ouvrir afin de récupérer vos colis
                    <br>
                    <br>
                    Astuce : Vous pouvez ouvrir plusieurs boxes en même temps !
                </p>
            </div>
            <div>
                <router-link to="/">
                    <BtnPrimary typeOfIconLeft="arrow-left" msg="J’ai fini" typeOfIconRight="logout"/>
                </router-link>
            </div>

        </section>

        <section class="section-action">
            <div class="type-one">Choisissez la commande que vous souhaitez retirer</div>

            <div class="container-packet-list">

                <div class="container-packet-items" v-if="packages.length > 0">
                    <template v-for="(parcel, index) in packages">
                        <!-- TODO : SAM => Si le box est ouverte rajouter la class "box-open" à côté de "item-packet", comme ci-dessous.
                            <div class="item-packet box-open" v-on:click="selectPackage(parcel.id)" v-if="index >= (page-1)*pageSize && index < page * pageSize">
                            </div>
                        -->
                        <div class="item-packet" :class="parcelClass(parcel)" v-on:click="selectPackage(parcel.id)" v-if="index >= (page-1)*pageSize && index < page * pageSize" :key="parcel.id" >
                            <div class="sticker">
                                <div class="icon-packet"></div>
                            </div>
                            <div class="content-information-packet">
                                <div>
                                    <div class="capitalize txt-deliveryman" v-if="parcel.senderId != this.$store.state.transaction.actor.id">Livré :
                                        <span v-if="parcel.senderId != null">{{ parcel.sender.firstName }} {{ parcel.sender.lastName }}</span>
                                        <span v-else>{{  parcel.senderName }}</span>
                                    </div>
                                    <div class="capitalize txt-deliveryman" v-else>Déposé : <span>{{ parcel.receiver.firstName }} {{ parcel.receiver.lastName }}</span></div>
                                </div>
                                <div class="small-txt">Date : {{ parcel.depositDate.toLowerCase() }}</div>
                            </div>
                            <div class="btn-direction">
                                <div class="icone-btn-arrow-right"></div>
                            </div>
                        </div>
                    </template>
                    <div class="container-paginate">
                        <button class="btn-paginate revers" :class="page <= 1 ? 'btn-disabled' : ''" v-if="paginate" v-on:click="prevPage()" :disabled="page <= 1"> v </button>
                        <button class="btn-paginate" :class="page >= Math.ceil(packages.length / pageSize) ? 'btn-disabled' : ''" v-if="paginate" v-on:click="nextPage()" :disabled="page >= Math.ceil(this.packages.length / pageSize)"> v </button>
                    </div>
                </div>
           <div class="container-packet-items message" v-else>
                    <p>Vous n'avez pas de colis à retirer pour le moment</p>
                </div>
            </div>

        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import BtnPrimary from "@/components/Atoms/BtnPrimary";
import moment from 'moment'
moment.locale('fr')
export default {
    name: 'CollectPackage',
    components: { BtnPrimary },
    data: function () {
        return {
            packages: [],
            page: 1,
            pageSize: 3,
            eventing: 'pushing', // pushing or polling > Polling is no longer supported
            openParcelIds: [],
            collectedParcelIds: [],
        }
    },
    computed: {
        paginate: function() {
            return this.packages.length > this.pageSize
        }
    },
    mounted: function() {
        let self = this;
        this.$emit('loading');
        let options = {
            emulateJSON: true,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.$store.state.auth.lockerToken
            }
        }
        this.$http.get(process.env.VUE_APP_API_BASE_URL + '/api/v1.0/user/' + this.$store.state.transaction.actor.id + '/transaction?state=delivered', options).then( response => {
            console.log(response)
            self.$emit('stopLoading')
            self.packages = response.data;
            self.packages.forEach(element => {
                element.depositDate = moment(element.depositDate).calendar()
            });
        })
        if (this.eventing == "pushing") {
            console.log('listener on')
            this.emitter.on("pusher", (data) => {
                console.log("pusher event received by the collect page")
                data = data.data
                console.log(data)
                let modulePosition = data.transaction.modulePosition
                let boxPosition = data.transaction.boxPosition
                let module = data.locker.modules.filter(elt => elt.physicalPositioning == modulePosition)[0]
                let box = module.status[parseInt(boxPosition)-1]
                console.log(module)
                console.log(box)
                console.log(this.packages)
                let transaction = this.packages.filter(elt => elt.modulePosition == modulePosition && elt.boxPosition == boxPosition)[0]
                console.log(transaction)
                if (box.lock == true && box.presence == false) {
                    this.collectedParcelIds.push(transaction.id)
                    this.openParcelIds = this.openParcelIds.filter(function(value) {
                        return value != transaction.id
                    });
                } else if (box.lock == true && box.presence == true) {
                    this.openParcelIds = this.openParcelIds.filter(function(value) {
                        return value != transaction.id
                    });
                } else if (box.lock == false) {
                    this.openParcelIds.push(transaction.id)
                }
            });
        }
    },
    unmounted: function() {
        this.emitter.off("pusher")
    },
    methods: {
        parcelClass: function(parcel) {
            if (this.collectedParcelIds.indexOf(parcel.id) > -1) {
                return 'box-completed'
            }
            if (this.openParcelIds.indexOf(parcel.id) > -1) {
                return 'box-open'
            }
        },
        selectPackage: function(packageId) {
            console.log(packageId)
            if (this.collectedParcelIds.indexOf(packageId) > -1) {
                console.log("we can not collect the same package twice...")
                return
            }
            if (this.openParcelIds.indexOf(packageId) > -1) {
                console.log("we can not collect the same package twice...")
                return
            }
            let self = this;
            this.$emit('loading')
            this.openParcelIds.push(packageId)
            console.log("Start transaction API call")
            let options = {
                emulateJSON: true,
                withCredentials: true,
                headers: {
                    Authorization: 'Bearer ' + this.$store.state.auth.lockerToken
                }
            }
            this.$http.put(process.env.VUE_APP_API_BASE_URL + '/api/v1.0/transaction/' + packageId, {}, options).then( response => {
                console.log(response)
                self.completeTransaction(response.data)
            }).catch(error => {
                console.log(error)
                self.$emit('stopLoading')
                alert('Une erreur est survenue (22)')
            })
        },
        completeTransaction: function(data) {
            this.$emit('stopLoading')
            this.$store.commit('setTransactionId', data);
            this.$store.commit('setTransactionBox', data.boxPosition);
            this.$store.commit('setTransactionModule', data.modulePosition);
            this.$store.commit('setTransactionLocker', data.lockerId);
        },
        prevPage: function() {
            this.page--;
        },
        nextPage: function() {
            this.page++;
        },
        leave: function() {
            this.$emit('leave')
        }
    }
}
</script>


<style lang="scss" scoped>


.message {
    font-size: 30px;
    text-align: center;
}

.container-packet-list {
    display: flex;
    height: 90%;
}

.container-packet-items {
    width: 800px;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .item-packet {
        //background-color: #DDDDDD;
        width: 973px;
        height: 100px;
        margin: 20px 0;
        padding: 21px 0 21px 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 3px solid #000000;
        .icon-packet {
            width: 70px;
            height: 70px;
            background-image: url("~@/assets/img/icons/icone-package.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 35px;
        }
        .content-information-packet {
            display: flex;
            width: 580px;
            height: 100%;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            .txt-deliveryman {
                font-family: "Avenir Next";
                font-size: 25px;
                font-weight: 500;
                color: #707070;
                span {
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
        }
        .btn-direction {
            height: 95px;
            width: 85px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .box-open {
        .btn-direction {
            visibility: hidden;
        }
    }
    .box-completed {
        opacity: 40%;
        .btn-direction {
            visibility: hidden;
        }
        .txt-deliveryman {
            span {
                color: #BABABA !important;
            }
        }
    }
}

.container-paginate {
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto;

    .btn-paginate {
        height: 70px;
        width: 70px;
        border-radius: 35px;
        background-color: #363636;
        color: #F7F7F7;
        font-family: Comfortaa;
        font-size: 30px;
        font-weight: 700;
        transform: rotate(-90deg);
    }

    .revers {
        transform: rotate(90deg);
    }
}

.txt-thirdly {
    font-family: "Avenir Next";
    font-weight: 400;
    font-size: 20px;
    color: #6E6E6E;
}

.small-txt {
    font-size: 18px;
    font-weight: 400;
    color: #6E6E6E;
}


</style>