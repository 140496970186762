<template>
    <div class="container-locker-position">
        <div class="content-lockers">
            <div v-for="(module) in locker" :key="module" class="items-module" :class="'items-module-' + module.type + ' module-type-' + module.type">
                <div v-for="(item, idx) in module.status" :key="item" :class="'items-box-' + item.size + ' ' + (item.lock == true ? '' : 'open') + ' ' + (item.presence ? 'full' : '')" @click="openBox(module.physicalPositioning, (idx+1))"></div>
            </div>
        </div>
        <!--<div class="icon-shoes"></div>-->
    </div>
</template>

<script>
    export default {
        name: 'Locker',
        props: ['locker'],
        data: function () {
            return {}
        },
        methods: {
            openBox: function(moduleIdx, boxIdx) {
                this.$emit('openBox', moduleIdx, boxIdx)
            }
        }
    }
</script>



<style lang="scss" scoped>

.container-depots-items{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    width: 100%;

    .container-txt-depots{
        width: 32%;
        padding-bottom: 20px;
        margin: auto;
        border-bottom: 1px solid gray;
    }

    .content-lockers{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 45px;

        .items-module{
            /* height: 330px;
            width: 180px; */
            height: 500px;
            width: 300px;
            border-radius: 10px;
            border: 3px solid #000;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            box-sizing: border-box;

            .items-box-s {
                height: 20%;
                width: 40%;
                border: 1px solid #222222;
            }
            .items-box-m {
                height: 20%;
                width: 60%;
                border: 1px solid #222222;
            }
            .items-box-l {
                height: 20%;
                width: 100%;
                border: 1px solid #222222;
            }
            .items-box-xl {
                height: 50%;
                width: 20%;
                border: 1px solid #222222;
            }
            .items-box-p-l {
                height: 45%;
                width: 20%;
                border: 1px solid #222222;
            }
            .items-box-p-xl {
                height: 55%;
                width: 20%;
                border: 1px solid #222222;
            }
            .items-box-screen {
                height: 20%;
                width: 60%;
                border: 1px solid #222222;
                background-color: #222222;
            }
        }

         // Mixed locker
        .module-type-3 .items-box-p-xl {
            height: 60%;
            width: 25%;
        }

        .items-module-0 .items-box-p-xl {
            height: 60%;
            width: 25%;
        }
    }

}

.container-locker-position {
    margin: auto;

    .icon-shoes {
        height: 45px;
        width: 70px;
        background-image: url("~@/assets/img/icons/icon_shoe@2x.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }
}

.content-depots {
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

</style>