import { createRouter, createWebHistory } from 'vue-router'
import Sleep from "@/views/Sleep";
import Start from '../views/Start.vue';
import Hold from '../views/Hold.vue';
import Login from '../views/Login.vue';
import ForgetLogin from "@/views/ForgetLogin";
import Information from "@/views/Information";

/* Delivery */
import DeliveryLogin from '../views/deposit/DeliveryLogin.vue';
import DeliveryRecipients from '../views/deposit/DeliveryRecipients.vue';
import DeliveryThanks from '../views/deposit/DeliveryThanks.vue';
import DeliveryLocker from "@/views/deposit/DeliveryLocker";

/* Pickup */
import CollectPackage from "@/views/pickup/CollectPackage";
import Pickup from "@/views/pickup/Pickup";

/* Ddebug */
import LockerStatus from "@/views/LockerStatus";

const routes = [
    {
        path: '/',
        name: 'Start',
        component: Start
    },
    {
        path: '/sleep',
        name: 'Sleep',
        component: Sleep
    },
    {
        path: '/hold',
        name: 'Hold',
        component: Hold
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        props: true
    },
    {
        path: '/deliverylogin',
        name: 'DeliveryLogin',
        component: DeliveryLogin
    },
    {
        path: '/deliveryrecipients',
        name: 'DeliveryRecipients',
        component: DeliveryRecipients
    },
    {
        path: '/deliverydepots',
        name: 'DeliveryDepots',
        component: DeliveryThanks
    },
    {
        path: '/deposit',
        name: 'Deposit',
        component: DeliveryLocker
    },
    {
        path: '/pickup',
        name: 'Pickup',
        component: Pickup
    },
    {
        path: '/collectpackage',
        name: 'CollectPackage',
        component: CollectPackage
    },
    {
        path: '/forgetlogin',
        name: 'ForgetLogin',
        component: ForgetLogin
    },
    {
        path: '/information',
        name: 'Information',
        component: Information
    },
    {
        path: '/lockerstatus',
        name: 'LockerStatus',
        component: LockerStatus
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router