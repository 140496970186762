<template>
    <div class="proposal" v-if="autocomplete">
        <button v-if="allowWriteIns" class="btn-suggestion" v-on:click="selectSuggestedItem(-1, msg)">
            <div class="bullet-point-user"></div>
            {{ msg }}
        </button>
        <button v-for="suggestion in suggestions" :key="suggestion.id" class="btn-suggestion" v-on:click="selectSuggestedItem(suggestion.id, suggestion.firstName + ' ' + suggestion.lastName)">
            <div class="bullet-point-user"></div>
            {{ suggestion.firstName }} {{ suggestion.lastName }}
        </button>
        <span v-if="suggestions.length == 0" class="italic">Pas de résultat</span>
    </div>
</template>

<script>



export default {
    name: 'Proposal',
    props: {
        msg: String,
        allowWriteIns: Boolean,
        autocomplete: String,
        suggestions: Array
    },
    data: function () {
        return {

        }
    },
    methods: {
        selectSuggestedItem(id, name) {
            let item = {
                id: id,
                name: name
            }
            this.$emit('itemSelected', item);
        }
    }
}

</script>

<style lang="scss">

.btn-suggestion {
    display: block
}

.proposal {

    .btn-suggestion {
        height: 80px;
        background-color: transparent;
        border: none;
        font-family: "Avenir Next";
        font-size: 28px;
        font-weight: 600;
    }
    .italic {
        font-family: "Avenir Next";
        font-size: 24px;
        font-weight: 400;
        font-style: italic;
        color: #222222;
    }
}

</style>