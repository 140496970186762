<template>
    <div id="login" class="page">
        <section class="section-information">
            <div class="container-information-content-1">
                <div class="container-breadcrumb">
                    <div class="bullet-point active">1</div>
                    <div class="bullet-point coming">2</div>
                    <div class="lign-breadcrumb"></div>
                </div>
                <div class="container-info-title">
                    <div class="icone-btn-information-small"></div>
                    <p class="information-title">Vos informations</p>
                </div>
                <p class="information-txt">
                    Scannez votre QR Code Clara Lockers reçu par e-mail à la création de votre compte. Placez votre QR Code devant la caméra en bas de cet écran.<br>
                    Sinon, vous pouvez manuellement saisir votre code d’identification Clara Lockers. <br>
                    <br>
                    <i>Pour toute question, contactez-nous : support@claralockers.com</i>
                </p>
            </div>
            <div>
                <BtnSecondary v-if="showManualLogin" v-on:click="toggleLoginType" typeOfIconLeft="arrow-left-white" msg="Retour" />
                <BtnSecondary v-if="showManualLogin" v-on:click="login" typeOfIconLeft="validation" msg="Valider" typeOfIconRight="arrow-right-white"/>
                <router-link to="/forgetlogin">
                    <BtnSecondary typeOfIconLeft="qr-code" msg="QR Code oublié ?" typeOfIconRight="arrow-right-white" />
                </router-link>
                <router-link to="/">
                    <BtnPrimary typeOfIconLeft="arrow-left" msg="Retour à l’accueil" typeOfIconRight="home"/>
                </router-link>
            </div>

        </section>

        <section class="section-action">
            <div v-if="!showManualLogin" class="container-login">
                <div class="type-one">Identifiez vous</div>

                <BtnPrimary v-on:click="toggleLoginType" msg="Saisissez votre code d’identification" />
                <div class="separation-space">
                    <hr>
                    <div class="txt-separation-space">Ou</div>
                    <hr>
                </div>
                <div class="type-one">QR code</div>
                <div class="return-camera">
                    <qrcode-stream @decode="onDecode" @init="onInit"></qrcode-stream>
                </div>
                <div class="camera-arrow"></div>
            </div>
            <div v-else class="container-login" v-on:click="clearError">
                <div class="type-one">Connectez-vous</div>
                <div class="keyboard-space">
                    <FormBasic codeField="true" numericField="true" @formValueChanged="readCode" />
                </div>
                <div class="errormsg" v-if="showFailedLogin">
                    Erreur: badge non reconnu
                </div>
                <div class="errormsg" v-if="showACLWarning">
                    Erreur: extra permissions requises
                </div>
            </div>
        </section>
    </div>
</template>

<script>
// https://github.com/gruhn/vue-qrcode-reader/issues/203
import { QrcodeStream } from 'vue-qrcode-reader'
// @ is an alias to /src
import BtnPrimary from "@/components/Atoms/BtnPrimary";
import BtnSecondary from "@/components/Atoms/BtnSecondary";
import FormBasic from "@/components/Organisms/FormBasic";

export default {
    name: 'Login',
    components: {FormBasic, BtnPrimary, QrcodeStream, BtnSecondary},
    props: ['redirectTo', 'acl'],
    data: function () {
        return {
            showManualLogin: false,
            showFailedLogin: false,
            showACLWarning: false,
            barcode: '',
            error: ''
        }
    },
    methods: {
        readCode: function(code) {
            this.barcode = code
        },
        toggleLoginType: function() {
            this.showManualLogin = !this.showManualLogin
        },
        clearError: function() {
            this.showFailedLogin = false;
            this.showACLWarning = false
        },
        login: async function() {
            this.showFailedLogin = false;
            let self = this
            let options = {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.auth.lockerToken
                }
            }
            this.$http.get(process.env.VUE_APP_API_BASE_URL + '/api/v1.0/user?accessCode=' + this.barcode.replaceAll('-', ''), options).then( response => {
                self.completeAuthentication(response.data)
            }).catch(error => {
                console.log(error)
                self.showFailedLogin = true;
            })
        },
        completeAuthentication: function(data) {
            if (this.redirectTo) {
                if (this.acl) {
                    switch(this.acl) {
                        case 'admin':
                            console.log(data)
                            if (Array.isArray(data)) data = data[0]
                            if (data.role == 'admin' || data.role == 1) {
                                console.log('this.redirectTo')
                                this.$router.push(this.redirectTo)
                                return
                            }
                        break;
                    }
                    this.showACLWarning = true
                    return
                } else {
                    this.$router.push(this.redirectTo)
                    return
                }
            }
            console.log("set actor start")
            if (Array.isArray(data)) data = data[0]
            console.log(data)
            this.$store.commit('setActor', data)
            console.log("set actor end")
            this.$emit('login', data)
            switch (this.$store.state.transaction.action) {
                /* case 'deposit':
                    this.$router.push('/deliveryrecipients')
                    break; */
                case 'conciergerie':
                    if (data.role == 'fidensio' || data.role == 'driver') {
                        this.$router.push('/')
                    } else {
                        this.$router.push('/services')
                    }
                    break;
                case 'withdrawal':
                    if (data.role == 'driver') {
                        this.$router.push('/')
                    } else {
                        this.$router.push('/collectpackage')
                    }
                    break;
            }
        },
        onDecode: function(qrcode) {
            // console.log(qrcode)
            let data = JSON.parse(qrcode);
            this.barcode = data.qrCodeId
            this.login();
        },
        async onInit (promise) {
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.error = "ERROR: you need to grant camera access permission"
                } else if (error.name === 'NotFoundError') {
                    this.error = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                    this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                    this.error = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                    this.error = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = "ERROR: Stream API is not supported in this browser"
                } else if (error.name === 'InsecureContextError') {
                    this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
                } else {
                    this.error = `ERROR: Camera error (${error.name})`;
                }
                console.log(error)
            }
        }
    }
}
</script>


<style lang="scss" scoped>

.container-login {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.container-login > button {
    margin: 20px 0;
}

.separation-space {
    width: 516px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    margin: 6px 0;

    hr {
        width: 40%;
        border: 1px solid #6E6E6E;
    }
    .txt-separation-space {
        font-size: 25px;
        font-weight: 400;
        color: #363636;
    }
}
.return-camera {
    width: 516px;
    height: 450px;
    border-radius: 25px;
    background: #222222 url("~@/assets/img/qr-code.gif") no-repeat center center;
    background-size: 80% 80%;
}
.camera-arrow {
    width: 16px;
    height: 28px;
    background-image: url("~@/assets/img/icons/icon_arrow_gray@2x.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(90deg);
    margin-top: 25px;
}

</style>