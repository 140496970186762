<template>
    <div id="locker" class="page">
        <section class="section-information">
            <div class="container-information-content-1">
                Depuis cette page, un administrateur peut avoir un état du casier.
            </div>
            <div>
                <router-link to="/">
                    <BtnPrimary typeOfIconLeft="arrow-left" msg="Retour à l’accueil" typeOfIconRight="home"/>
                </router-link>
            </div>
        </section>
        <section class="section-action">
            <div class="type-one">Statut du Casier</div>
            <div class="container-depots-items">
                <div class="content-depots">
                    <Locker :locker="locker.modules" @openBox="openBox" />
                    <!--<BtnPrimary msg="Retour" @click="goHome" />-->
                </div>
            </div>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import Locker from "@/components/Organisms/Locker";
import BtnPrimary from "@/components/Atoms/BtnPrimary";

export default {
  name: 'LockerStatus',
  components: { Locker, BtnPrimary},
  data: function () {
      return {
          locker : this.$store.state.locker
      }
    },
    methods: {
        openBox: function(moduleIdx, boxIdx) {
            let options = {
                emulateJSON: true,
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${this.$store.state.auth.lockerToken}`
                }
            }
            this.$http.post(process.env.VUE_APP_API_BASE_URL + '/api/v1.0/locker/' + this.$store.state.auth.lockerId + '/module/' + moduleIdx + '/box/' + boxIdx, {}, options).then( response => {
                console.log(response)
            })
        },
        goHome: function() {
            console.log('go home (locker status page)')
            this.$emit('wake')
        }
    },
    mounted: function() {
        // console.log(this.locker)
        this.emitter.on("pusher", (data) => {
            console.log("pusher event received by the status page")
            // console.log(data)
            // data = data.data
            this.locker = data.data.locker
        });
    },
    unmounted: function() {
        this.emitter.off("pusher")
    }
}
</script>

<style scoped>
.btn-primary {
    margin: 0 auto;
}
</style>