<template>
    <div id="forget-login" class="page">
        <section class="section-information">
            <div class="container-information-content-1">
                <div class="container-breadcrumb">
                    <div class="bullet-point active">1</div>
                    <div class="bullet-point coming">2</div>
                    <div class="lign-breadcrumb"></div>
                </div>
                <div class="container-info-title">
                    <div class="icone-btn-information-small"></div>
                    <p class="information-title">Vos informations</p>
                </div>
                <p class="information-txt">
                    Indiquez votre nom et prénom et vous recevrez un email contenant votre QR Code à l’adresse indiquée lors de l'inscription.
                    <br>
                    <br>
                    <i>Pour toute question, contactez-nous : support@claralockers.com</i>
                </p>
            </div>
            <div>
                <router-link to="/login"><BtnSecondary typeOfIconLeft="arrow-left-white" msg="Précédent" /></router-link>
                <router-link to="/"><BtnPrimary typeOfIconLeft="arrow-left" msg="Retour à l’accueil" typeOfIconRight="home"/></router-link>
            </div>

        </section>

        <section class="section-action">
            <div class="type-one">Identifiez-vous</div>
            <FormBasic @itemSelected="submitForgottenQRCode" autocomplete="users" />
        </section>

        <div class="modal confirm" v-if="showConfirmationModal">
            <h1>Merci</h1>
            <p>
                Un email vous a été envoyé,<br>
                consultez votre boite mail pour retrouver votre QR Code.<br>
                <br>
                À bientôt
            </p>
            <br>
            <BtnPrimary msg="Se connecter" v-on:click="goBack" :loader="false" />
        </div>
    </div>
</template>

<script>
import FormBasic from "@/components/Organisms/FormBasic";
import BtnPrimary from "@/components/Atoms/BtnPrimary";
import BtnSecondary from "@/components/Atoms/BtnSecondary";

export default {
    name: 'DeliveryLogin',
    components: {FormBasic, BtnPrimary, BtnSecondary},
    data: function () {
        return {
            allowWriteIns: false,
            showConfirmationModal: false
        }
    },
    methods: {
        submitForgottenQRCode: function(value) {
            console.log('submit forgotten qrcode for :')
            console.log(value)
            this.$emit('startLoading')
            let self = this
            let options = {
                emulateJSON: true,
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${this.$store.state.auth.lockerToken}`
                }
            }
            this.$http.post(process.env.VUE_APP_API_BASE_URL + '/api/v1.0/user/' + value.id + '/askAccessCode', {}, options).then( response => {
                self.$emit('stopLoading')
                console.log(response.data)
                this.showConfirmationModal = true
            }).catch(error => {
                console.log(error)
                self.$emit('stopLoading')
                alert('Une erreur est survenue (77)')
            })
        },
        goBack: function() {
            this.$router.go(-1)
        }
    }
}
</script>