<template>
    <div class="page">
        <section class="section-information">
            <div class="container-information-content-1">
                <div class="cards-btn-infomation" v-on:click="readInformationOne">
                    <p>{{ titleInformationOne }}</p>
                    <div class="icone-btn-arrow-right-white"></div>
                </div>
                <div class="cards-btn-infomation" v-on:click="readInformationTwo">
                    <p>{{ titleInformationTwo }}</p>
                    <div class="icone-btn-arrow-right-white"></div>
                </div>
                <!--<div class="cards-btn-infomation" v-on:click="readInformationThree">
                    <p>{{ titleInformationThree }}</p>
                    <div class="icone-btn-arrow-right"></div>
                </div>-->
            </div>
            <div>
                <div class="cards-btn-infomation" v-on:click="goToStatus">
                    <p>Administrateurs</p>
                    <div class="icone-btn-arrow-right-white"></div>
                </div>
                <router-link to="/">
                    <BtnPrimary typeOfIconLeft="arrow-left" msg="Retour à l’accueil" typeOfIconRight="home"/>
                </router-link>
            </div>

        </section>

        <section class="section-action">
            <div class="type-one">Vos informations</div>
            <div class="container-body-information">
                <div :class="txtOne">
                    <p class="title-information"> {{ titleInformationOne }} </p>
                    <p class="subtitle-information">Vous avez déjà un compte ?</p>
                    <p class="text-information">
                        Vous devez déjà avoir créé un compte Clara Lockers pour pouvoir vous faire livrer à cette adresse.
                    </p>

                    <p class="subtitle-information margin-plus">Vous n'avez pas encore de compte ?</p>
                    <p class="text-information">
                        Pour créer un compte, vous pouvez vous inscrire en 3 clics depuis votre téléphone portable en scannant le QR code ci-dessous. Veillez à utiliser votre adresse e-mail professionnelle.
                        <br>
                        <img src="@/assets/img/signupqrcode.png" class="qrcode" alt="Signup QR Code">
                        <br>
                        Vous recevrez un e-mail avec votre QR code Clara Lockers qui vous permettra d’utiliser ces casiers quand vous vous faites livrer.
                    </p>

                    <p class="subtitle-information margin-plus">Besoin d'aide ?</p>
                    <p class="text-information">
                        Pour toute question, contactez-nous : support@claralockers.com
                    </p>
                </div>
                <div :class="txtTwo">
                    <p class="title-information"> {{ titleInformationTwo }} </p>
                    <p class="subtitle-information"></p>
                    <p class="text-information">
                        Une fois que vous avez créé votre compte Clara Lockers, vous pourrez livrer de n’importe quel site web, en indiquant l’adresse suivante : <br>
                        <span class="address">
                            Votre nom<br>
                            [[ Adresse de votre entreprise (cet immeuble) ]]<br>
                            Clara Lockers<br>
                            [[ Code Postal ]] [[ Ville de l'entreprise ]]
                        </span>
                        <br>
                        Quand vous recevez la notification par e-mail que votre colis est arrivé :<br>
                        <span class="steps">
                            - Présentez-vous devant les casiers.<br>
                            - À la page d’accueil sélectionnez l’option &lsquo;Retirer&rsquo;.<br>
                            - Scannez devant la caméra du casier le QR Code que vous avez reçu à la création de votre compte.<br>
                            - La porte du casier contenant votre colis sera automatiquement ouverte.<br>
                            - Vous recevrez un e-mail de confirmation que vous avez retiré votre colis.<br>
                        </span>
                        <br>
                        À vous de jouer !
                    </p>

                    <p class="subtitle-information margin-plus">Besoin d'aide ?</p>
                    <p class="text-information">
                        Pour toute question, contactez-nous : support@claralockers.com
                    </p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import BtnPrimary from "@/components/Atoms/BtnPrimary";

export default {
    name: 'Information',
    components: { BtnPrimary },
    data: function () {
        return {
            titleInformation: '',
            // titleInformationOne: 'Des casiers multifonctions !',
            // titleInformationTwo: 'Avant d’utiliser les casiers',
            // titleInformationThree: 'Comment procéder ?',
            titleInformationOne: 'Comment créer son compte ?',
            titleInformationTwo: 'Comment utiliser les casiers ?',
            txtOne: 'show',
            txtTwo: 'hide',
            txtThree: 'hide'
        }
    },
    methods: {
        backPage: function() {
            this.$router.push('/carwashcleaningservice');
        },
        readInformationOne: function() {
            this.txtOne = 'show';
            this.txtTwo= 'hide';
            this.txtThree= 'hide';
        },
        readInformationTwo: function() {
            this.txtOne = 'hide';
            this.txtTwo= 'show';
            this.txtThree= 'hide';
        },
        readInformationThree: function() {
            this.txtOne = 'hide';
            this.txtTwo= 'hide';
            this.txtThree= 'show';
        },
        goToStatus: function() {
            this.$router.push({ name: 'Login', params: {redirectTo: '/lockerstatus', acl: 'admin'}})

        }
    }
}
</script>

<style lang="scss" scoped>

.cards-btn-infomation {
    height: 95px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 25px;
    font-weight: 500;
    color: #fff;
    padding: 0 18px 0 30px;
    margin: 30px 0;
}

.container-body-information {
    width: 70%;
    height: 98%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 60px auto 0 auto;
    text-align: start;
    .title-information {
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 28px;
    }
    .subtitle-information {
        font-size: 35px;
        font-weight: 600;
        margin-bottom: 18px;
    }
    .margin-plus {
        margin-top: 30px;
    }
    .text-information {
        font-size: 27px;
        font-weight: 500;
    }
}
.qrcode {
    width: 200px;
    height: auto;
    margin: 20px auto;
}
.address {
    display: block;
    margin-left: 60px;
    font-style: italic;
    font-weight: 400;
}
.steps {
    display: block;
    margin-left: 60px;
    font-weight: 400;
}
.show {
    display: block;
}

.hide {
    display: none;
}

</style>